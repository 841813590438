<template>
  <div class="bind-account">
    <h3 class="text-size-h3 text-bold mb-s">Аккаунт Яндекс&nbsp;ID</h3>
    <div v-if="pending"><BaseSpinner brand /></div>
    <div v-else>
      <div v-if="requireYandex">
        <div v-if="onProgress">
          <BaseSpinner brand />
          <p>Ожидаем привязки аккаунта на платформе Талант</p>
        </div>
        <div v-else>
          <div v-if="progressError">
            {{ progressError }} <br />
            <a
              href="#"
              class="link link--pseudo"
              @click.prevent="handleRetry"
              >Попробовать еще раз</a
            >
          </div>
          <div v-else>
            <div class="bind-account__text">
              <div v-if="!accounts.length">
                <div class="mb-m">
                  <p class="mb-s">
                    К вашей учетной записи «Талант» не привязан аккаунт Яндекс
                    ID.
                  </p>
                  <p class="mb-s">
                    Задания отборочных этапов НТО мы&nbsp;размещаем
                    на&nbsp;платформе Яндекс.Кон­тест. Чтобы дать вам доступ
                    к&nbsp;заданиям, мы&nbsp;должны знать ваш аккаунт
                    Яндекс&nbsp;ID.
                  </p>
                  <p class="mb-s">
                    Привязать свой аккаунт Яндекс&nbsp;ID к&nbsp;НТО можно
                    в&nbsp;личном кабинете на&nbsp;платформе
                    &laquo;Талант&raquo;&nbsp;&mdash; вы&nbsp;попадете туда,
                    нажав на&nbsp;кнопку ниже.
                  </p>
                </div>
                <BaseButton @click.prevent="openBindWindow"
                  >Привязать Яндекс&nbsp;ID</BaseButton
                >
              </div>
              <div v-else>
                <p class="mb-m">
                  Выберите аккаунт Яндекс&nbsp;ID, с&nbsp;которого
                  вы&nbsp;будете решать задания НТО. Несколько важных моментов:
                  <br />
                  – Доступ к&nbsp;заданиям будет только с&nbsp;выбранного
                  аккаунта.<br />
                  – Привязать другой Яндекс&nbsp;ID не&nbsp;получится, поэтому
                  проверьте, что у&nbsp;вас действительно есть доступ
                  к&nbsp;этому аккаунту.<br />
                  – Использовать несколько аккаунтов Яндекс&nbsp;ID
                  нельзя&nbsp;&mdash; вас дисквалифицируют.
                </p>
                <div
                  v-for="item in accounts"
                  :key="item.id"
                  class="account mb-m">
                  <div>Пользователь: {{ item.email || item.uid }}</div>
                  <div v-if="item.profile_url">
                    Ссылка на профиль:
                    <a
                      class="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      :href="item.profile_url"
                      >{{ item.profile_url }}</a
                    >
                  </div>
                  <div>
                    <a
                      href="#"
                      class="link link--pseudo"
                      @click.prevent="bindAccount(item)"
                      >Выбрать этот аккаунт</a
                    >
                  </div>
                </div>
                <BaseButton
                  v-if="showRefresh"
                  class="link link--pseudo"
                  :disabled="updatePending"
                  @click.prevent="handleUpdateList"
                  >Обновить список аккаунтов</BaseButton
                >
                <a
                  v-else
                  class="link"
                  href="#"
                  @click.prevent="bindAnotherAccount"
                  >Привязать другой аккаунт</a
                >
              </div>
            </div>
            <div
              v-if="error"
              class="mb-m form-error">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="yandexId">
        <p class="mb-m">
          Вы используете аккаунт Яндекс&nbsp;ID
          <span v-if="yandexEmail"
            ><strong class="text-bold">{{ yandexEmail }}</strong> [uid:
            {{ yandexId }}]</span
          >
          <br />Если у вас возникли трудности с привязкой аккаунта,
          <router-link
            class="link"
            :to="{ name: 'support' }"
            >напишите нам</router-link
          >, мы поможем.
        </p>
        <p>
          <strong class="text-bold">Обязательно познакомьтесь</strong>
          с&nbsp;платформой Яндекс.Контест заранее, <br />чтобы не тратить время
          на экзамене.
        </p>
        <BaseButton
          tag="a"
          class="mt-m"
          target="_blank"
          theme="subtle"
          href="https://contest.yandex.ru/contest/67576/problems/"
          >Ознакомиться</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmBindYandex from "@/components/user/modals/ConfirmBindYandex";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
import { config } from "@/config";
const MAX_RETRY_COUNT = 20;
const RETRY_DELAY = 3000;
export default {
  name: "BindYandex",
  data() {
    return {
      pending: true,
      error: "",
      onProgress: false,
      checkStatusTimer: null,
      progressError: "",
      retryCount: 0,
      showRefresh: false,
      updatePending: false,
      initialAccountsLength: 0,
    };
  },
  computed: {
    accounts() {
      return this.$store.state.user.yandexAccounts;
    },
    bindAccountURL() {
      const base = config.TALENT_BASE_URL;
      return `${base}/oauth/yandex/go`;
    },
    requireYandex() {
      return this.$store.getters["user/requireYandex"];
    },
    yandexId() {
      return this.$store.getters["user/yandexId"];
    },

    yandexEmail() {
      const { yandexId, accounts } = this;
      if (!yandexId) return;
      return accounts.find((n) => n.uid == yandexId)?.email;
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.handleStopPolling();
  },
  methods: {
    async init() {
      if (!this.requireYandex) {
        this.pending = false;
        return;
      }
      this.pending = true;
      try {
        const accs = await this.$store.dispatch("user/getYandexAccount");
        this.initialAccountsLength = accs?.length || 0;
      } catch (error) {
        this.error =
          "Произошла ошибка. Не удалось получить список аккаунтов Яндекс ID от платформы Талант";
      }
      this.pending = false;
    },
    bindAccount(account) {
      this.$modal.show(
        ConfirmBindYandex,
        {
          account,
        },
        MODAL_DYNAMIC_DEFAULTS,
        {
          closed: this.checkYandexId,
        }
      );
    },
    checkYandexId() {
      if (!this.requireYandex) {
        this.$emit("done");
      }
    },
    handleStopPolling(error) {
      this.onProgress = false;
      this.retryCount = 0;
      clearTimeout(this.checkStatusTimer);
      if (error) {
        this.progressError = error;
      }
    },
    pollingAccounts() {
      this.onProgress = true;
      if (this.retryCount > MAX_RETRY_COUNT) {
        this.handleStopPolling("Время ожидание ответа истекло");
        return;
      }
      this.checkStatusTimer = setTimeout(async () => {
        this.retryCount += 1;
        try {
          const accounts = await this.$store.dispatch("user/getYandexAccount");
          if (accounts?.length > this.initialAccountsLength) {
            this.handleStopPolling();
          } else {
            this.pollingAccounts();
          }
        } catch (error) {
          this.handleStopPolling(error.message);
        }
      }, RETRY_DELAY);
    },
    openBindWindow() {
      this.handleStopPolling();
      window.open(this.bindAccountURL, "_blank");
      this.pollingAccounts();
    },
    bindAnotherAccount() {
      this.showRefresh = true;
      window.open(this.bindAccountURL, "_blank");
    },
    async handleUpdateList() {
      if (this.updatePending) return;
      this.updatePending = true;
      try {
        await this.$store.dispatch("user/getYandexAccount");
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось обновить список аккаунтов",
          message: error.message,
          status: error?.response?.status,
          url: "user/getYandexAccount",
        });
      }
      this.updatePending = false;
    },

    handleRetry() {
      this.progressError = "";
      this.openBindWindow();
    },
  },
};
</script>

<style lang="less" scoped>
.bind-account {
  &__text {
    max-width: 690px;
  }
}

.account {
  padding: 12px;
  border: 1px solid fade(#000, 10%);
  border-radius: @radius-l;
}
</style>
